import { useApi } from '@backstage/core-plugin-api'
import { catalogApiRef } from '@backstage/plugin-catalog-react'
import useAsync from 'react-use/lib/useAsync'
import { useSelf } from '../../../hooks/useUserEntity'

export const useHelperDocs = () => {
  const catalogApi = useApi(catalogApiRef)
  const { value: userEntity } = useSelf()

  return useAsync(async () => {
    const memberOf = userEntity?.spec?.memberOf as []

    if (!memberOf) return {}

    const filter = memberOf.map((t: string) => ({
      kind: 'resource',
      'spec.type': 'helper-docs',
      'spec.owner': t,
    }))

    return catalogApi.getEntities({ filter })
  }, [userEntity])
}
