import { Content, Page } from '@backstage/core-components'
import { SearchContextProvider } from '@backstage/plugin-search-react'
import React from 'react'
import HomeHeader from './HomeHeader'
import Dashboard from './Dashboard'

export const HomePage = () => {
  return (
    <SearchContextProvider>
      <Page themeId="home">
        <HomeHeader />
        <Content>
          <Dashboard />
        </Content>
      </Page>
    </SearchContextProvider>
  )
}
