import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api'

import { rootRouteRef } from './routes'

export const ephemeralsPlugin = createPlugin({
  id: 'ephemerals',
  routes: {
    root: rootRouteRef,
  },
})

export const EphemeralsPage = ephemeralsPlugin.provide(
  createRoutableExtension({
    name: 'EphemeralsPage',
    component: () => import('./components/EphemeralsPage').then(m => m.default),
    mountPoint: rootRouteRef,
  }),
)

export const EphemeralPage = ephemeralsPlugin.provide(
  createRoutableExtension({
    name: 'EphemeralPage',
    component: () => import('./components/EphemeralPage').then(m => m.default),
    mountPoint: rootRouteRef,
  }),
)

export const CreateEphemeralPage = ephemeralsPlugin.provide(
  createRoutableExtension({
    name: 'CreateEphemeralPage',
    component: () =>
      import('./components/CreateEphemeralPage').then(m => m.default),
    mountPoint: rootRouteRef,
  }),
)
