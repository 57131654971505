import { InfoCard } from '@backstage/core-components'
import { EntityProvider } from '@backstage/plugin-catalog-react'
import { HomePageStarredEntities } from '@backstage/plugin-home'
import { OwnershipCard, UserProfileCard } from '@backstage/plugin-org'
import { Grid as BaseGrid, GridItem, Spinner } from '@beam-tech/ui' // @ts-ignore
import React from 'react'
import styled from 'styled-components'
import { useSelf } from '../../hooks/useUserEntity'
import { HelpfulDocs } from '../helpfulDocs'
import SearchBar from './SearchBar'

const Grid = styled(BaseGrid)`
  margin: 0px;
`

const GithubUserWidgets = () => (
  <>
    <GridItem numCols={4}>
      <UserProfileCard variant="flex" />
      <br />
      <OwnershipCard variant="flex" />
    </GridItem>
    <GridItem numCols={4}>
      <HomePageStarredEntities />
      <br />
      <HelpfulDocs />
    </GridItem>
  </>
)

const GoogleUserWidgets = () => (
  <>
    <GridItem numCols={4}>
      <InfoCard title="Welcome!" variant="gridItem">
        <p>
          <b>
            To view the catalog, click the &quot;Catalog&quot; button on the
            left navbar.
          </b>
        </p>
        <p>
          <i>
            NOTE: You are not logged into your Github account, so you will not
            see profile or owned entity information. If you are a developer,
            please log out of Google (click &quot;Settings&quot; in the left
            navbar and open the &quot;Authentication Providers&quot; tab) and
            log in using Github to use these features.
          </i>
        </p>
      </InfoCard>
    </GridItem>
    <GridItem numCols={4}>
      <HomePageStarredEntities />
    </GridItem>
  </>
)

const UserWidgets = () => {
  const { value: userEntity, loading } = useSelf()
  const isGithubUser = !!userEntity

  if (loading) return <Spinner />

  return (
    <EntityProvider entity={userEntity}>
      <br />
      <Grid>
        <GridItem numCols={8}>
          <SearchBar />
        </GridItem>
      </Grid>
      <Grid>
        {isGithubUser ? <GithubUserWidgets /> : <GoogleUserWidgets />}
      </Grid>
    </EntityProvider>
  )
}

export default UserWidgets
