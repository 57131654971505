import { identityApiRef, useApi } from '@backstage/core-plugin-api'
import { catalogApiRef } from '@backstage/plugin-catalog-react'
import useAsync from 'react-use/lib/useAsync'

export const useSelf = () => {
  const identityApi = useApi(identityApiRef)
  const catalogApi = useApi(catalogApiRef)

  return useAsync(async () => {
    const { userEntityRef } = await identityApi.getBackstageIdentity()

    return catalogApi.getEntityByRef(userEntityRef)
  })
}
