import { SignInPage } from '@backstage/core-components'
import {
  configApiRef,
  githubAuthApiRef,
  googleAuthApiRef,
  discoveryApiRef,
  useApi,
  type IdentityApi,
} from '@backstage/core-plugin-api'
import React from 'react'

// Parses supplied JWT token and returns the payload
function parseJwt(token: string): { exp: number } {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

// Returns milliseconds until the supplied JWT token expires
function msUntilExpiry(token: string): number {
  const payload = parseJwt(token)
  const remaining =
    new Date(payload.exp * 1000).getTime() - new Date().getTime()
  return remaining
}

// Calls the specified url regularly using an auth token to set a token cookie
// to authorize regular HTTP requests when loading techdocs
async function setTokenCookie(url: string, identityApi: IdentityApi) {
  const { token } = await identityApi.getCredentials()
  if (!token) {
    return
  }

  await fetch(url, {
    mode: 'cors',
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  // Call this function again a few minutes before the token expires
  const ms = msUntilExpiry(token) - 4 * 60 * 1000
  // eslint-disable-next-line scanjs-rules/call_setTimeout
  setTimeout(
    () => {
      setTokenCookie(url, identityApi)
    },
    ms > 0 ? ms : 10000,
  )
}

export const SignIn = (props: any) => {
  const configApi = useApi(configApiRef)
  const discoveryApi = useApi(discoveryApiRef)

  const googleProvider = {
    id: 'google-auth-provider',
    title: 'Google',
    message: 'Sign in using Google. Use this path it you are not a developer.',
    apiRef: googleAuthApiRef,
  }
  const githubProvider = {
    id: 'github-auth-provider',
    title: 'Developer login (Github)',
    message: 'Sign in using GitHub. Use this path it you are a developer.',
    apiRef: githubAuthApiRef,
  }
  const developmentProvider =
    configApi.getOptionalString('auth.environment') === 'development'
      ? 'guest'
      : undefined

  const providers = [
    githubProvider,
    googleProvider,
    developmentProvider,
  ].filter(Boolean)

  return (
    <SignInPage
      {...props}
      auto
      align="center"
      providers={providers}
      onSignInSuccess={async (identityApi: IdentityApi) => {
        setTokenCookie(await discoveryApi.getBaseUrl('cookie'), identityApi)

        props.onSignInSuccess(identityApi)
      }}
    />
  )
}
