import React from 'react'
import { HeaderWorldClock } from '@backstage/plugin-home'
import { Header } from '@backstage/core-components'

const clockConfigs = [
  {
    label: 'Eastern',
    timeZone: 'US/Eastern',
  },
  {
    label: 'Central',
    timeZone: 'US/Central',
  },
  {
    label: 'Mountain',
    timeZone: 'US/Mountain',
  },
  {
    label: 'Pacific',
    timeZone: 'US/Pacific',
  },
]

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
}

const HomeHeader = () => (
  <Header title="Developer Portal" pageTitleOverride="Home">
    <HeaderWorldClock
      clockConfigs={clockConfigs}
      customTimeFormat={timeFormat}
    />
  </Header>
)

export default HomeHeader
