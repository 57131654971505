import type { ChangeEvent } from 'react'
import React, { useState } from 'react'
import { Icon, TextInput } from '@beam-tech/ui'
import { faSearch as searchIcon } from '@beam-tech/icons/light'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const StyledForm = styled.form`
  width: 100%;
  & > div {
    width: 100%;
  }
`

const SearchInput = styled(TextInput)`
  max-width: 60vw;
  margin: auto;
`

const SearchBar = () => {
  const navigate = useNavigate()
  const [query, setQuery] = useState<string>('')
  const handleSubmit = () => {
    navigate(`/search?query=${encodeURIComponent(query)}`)
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setQuery(e.target.value)

  return (
    <StyledForm onSubmit={handleSubmit}>
      <SearchInput
        value={query}
        onChange={handleChange}
        leftIcon={<Icon icon={searchIcon} />}
      />
    </StyledForm>
  )
}

export default SearchBar
