/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import type { PropsWithChildren } from 'react'
import React, { useContext } from 'react'
import {
  useStarredEntities,
  catalogApiRef,
} from '@backstage/plugin-catalog-react'
import { useApi } from '@backstage/core-plugin-api'
import { Icon as BaseIcon, Theme, light } from '@beam-tech/ui'
import { faPuzzlePiece as extensionIcon } from '@beam-tech/icons/solid'
import {
  faSitemap as sitemapIcon,
  faBars as menuIcon,
  faSearch as searchIcon,
  faBooks as booksIcon,
  faServer as serverIcon,
  faPlusCircle as createComponentIcon,
  faHome as homeIcon,
} from '@beam-tech/icons/light'
import { NavLink, Link as BaseRRLink } from 'react-router-dom'
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings'
import {
  Sidebar,
  sidebarConfig,
  SidebarContext,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
} from '@backstage/core-components'
import { Shortcuts } from '@backstage/plugin-shortcuts'
// @ts-ignore
import bbsConfig from '@beam-tech/config/configs/bbs' // eslint-disable-line import/extensions, import/no-unresolved
import { ConfigProvider, getEnvConfig } from '@beam-tech/config'
import styled from 'styled-components'
import useAsync from 'react-use/lib/useAsync'
import LogoIcon from './LogoIcon'
import LogoFull from './LogoFull'

const config = getEnvConfig(bbsConfig)

const SidebarLogoRoot = styled.div`
  width: ${sidebarConfig.drawerWidthClosed}px;
  height: ${3 * sidebarConfig.logoHeight}px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: -14px;
`

const SidebarLogoLink = styled(NavLink)`
  width: ${sidebarConfig.drawerWidthClosed}px;
  margin-left: 24px;
`

const SidebarLogo = () => {
  const { isOpen } = useContext(SidebarContext)

  return (
    <SidebarLogoRoot>
      <SidebarLogoLink to="/">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </SidebarLogoLink>
    </SidebarLogoRoot>
  )
}

const Icon = styled(BaseIcon)`
  font-size: 20px;
`
const SitemapIcon = () => <Icon icon={sitemapIcon} />
const SearchIcon = () => <Icon icon={searchIcon} />
const MenuIcon = () => <Icon icon={menuIcon} />
const ExtensionIcon = () => <Icon icon={extensionIcon} />
const BooksIcon = () => <Icon icon={booksIcon} />
const EphemeralsIcon = () => <Icon icon={serverIcon} />
const CreateComponentIcon = () => <Icon icon={createComponentIcon} />
const HomeIcon = () => <Icon icon={homeIcon} />

const RRLink = ({
  href,
  ...rest
}: Omit<React.ComponentProps<typeof BaseRRLink>, 'to'> & { href: string }) => (
  <BaseRRLink to={href} {...rest} />
)

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const { starredEntities } = useStarredEntities()
  const catalogApi = useApi(catalogApiRef)
  const { value: starredEntityObjs } = useAsync(
    () =>
      Promise.all(
        [...starredEntities].map(async ownershipRef =>
          catalogApi.getEntityByRef(ownershipRef),
        ),
      ),
    [starredEntities],
  )

  return (
    <ConfigProvider defaultConfig={config}>
      <Theme theme={light} linkComponents={{ internal: RRLink }}>
        <SidebarPage>
          <Sidebar>
            <SidebarLogo />
            <SidebarDivider />
            <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
              <SidebarItem icon={SearchIcon} to="/search" text="Search" />
            </SidebarGroup>
            <SidebarDivider />
            <SidebarGroup label="Menu" icon={<MenuIcon />}>
              {/* Global nav, not org-specific */}
              <SidebarItem icon={HomeIcon} to="/" text="Home" />
              <SidebarItem
                icon={SitemapIcon}
                to="catalog?filters[kind]=domain&filters[user]=all"
                text="Catalog"
              />
              <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
              <SidebarItem icon={BooksIcon} to="docs" text="Docs" />
              <SidebarItem
                icon={EphemeralsIcon}
                to="ephemerals"
                text="Ephemerals"
              />
              <SidebarItem
                icon={CreateComponentIcon}
                to="create"
                text="Create..."
              />
              {/* End global nav */}
              <SidebarDivider />
            </SidebarGroup>
            <Shortcuts />
            <SidebarGroup>
              {starredEntityObjs?.map(
                entity =>
                  entity && (
                    <SidebarItem
                      key={entity.metadata.uid}
                      icon={SitemapIcon}
                      to={`catalog/${entity.metadata.name}`}
                      text={entity.metadata.name}
                    />
                  ),
              )}
            </SidebarGroup>
            <SidebarSpace />
            <SidebarDivider />
            <SidebarGroup
              label="Settings"
              icon={<UserSettingsSignInAvatar />}
              to="/settings"
            >
              <SidebarSettings />
            </SidebarGroup>
          </Sidebar>
          {children}
        </SidebarPage>
      </Theme>
    </ConfigProvider>
  )
}
