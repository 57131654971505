import { InfoCard, Progress } from '@backstage/core-components'
import { Icon, Link, Banner } from '@beam-tech/ui'
import { faGlobe as globeIcon } from '@beam-tech/icons/light'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useHelperDocs } from './hooks/useHelperDocs'

const LogoLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  font-size: 1rem;
`

const StyledIcon = styled(Icon)`
  color: #000;
`

const LinkContainer = styled.div`
  padding: 8px;
`

const EmptyDocsLabel = styled.div`
  font-size: 1rem;
`

const HelperDocLink = (
  {
    metadata: {
      annotations: { 'helper-docs/url': url },
      title,
    },
  }: any,
  i: number,
) => {
  return (
    <LinkContainer>
      <LogoLink href={url} key={`${title}_${i}`}>
        <StyledIcon icon={globeIcon} /> {title}
      </LogoLink>
    </LinkContainer>
  )
}

const HelperDocs = ({ entities }: any) => {
  if (!entities || !entities.items)
    return (
      <EmptyDocsLabel>
        You do not have any helpful docs registered!
      </EmptyDocsLabel>
    )

  return entities.items.map(HelperDocLink)
}

const Error = ({ error }: any) => {
  const [showError, setShowError] = useState(!!error)

  if (!showError) return null

  return (
    <Banner
      brand="danger"
      message={error.message}
      onDismiss={() => setShowError(false)}
      shouldDisplay
    />
  )
}

export const HelpfulDocs = () => {
  const { value: entities, loading, error } = useHelperDocs()

  if (loading) return <Progress />

  return (
    <InfoCard title="Helpful Docs" variant="flex">
      <Error error={error} />
      <HelperDocs entities={entities} />
    </InfoCard>
  )
}
